const $logTable = $('#botlog').DataTable({
	info: false,
	pageLength: 20,
	ordering: false,
	dom: 'rtp',
})

$('#logtype_filter').on('change', function() {
	const regexr = '({search})';
	let searchValue = $(this).val();

	if ($(this).val() === 'WARN') {
		searchValue = 'WARN|ERROR|CRITICAL|Exception'
	}

	if($(this).val() === 'INFO') {
		searchValue = 'INFO|Status'
	}


	$logTable.column(2).search(
		this.value !== ''
			? regexr.replace('{search}', '(((' + searchValue + ')))')
			: '',
		searchValue != '',
		searchValue == ''
	).draw()
})

$('#logpage_length').on('change', function() {
	$logTable.page.len($(this).val()).draw();
})