require('./bootstrap')

require('./app/bots/create')
require('./app/bots/list')
require('./app/bots/logs')
require('./app/bots/functions')
require('./app/bots/manage')


require('./app/user/security')


require('./app/auth/register')