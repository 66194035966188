import 'datatables.net-bs5'
import './common/extends'
import './common/bootstrap'

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.baseURL = '/api/v1/';



// Reset Validation for inputs on type
$('.reset-validation').on('keyup keypress', function () {
	$(this).removeClass('is-invalid')
	$(this).parent().find('.invalid-feedback').text("")
})