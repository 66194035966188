import toastr from 'toastr'

toastr.options = {
	"closeButton": false,
	"progressBar": true,
	"onclick": null,
	"newestOnTop": true,
	"preventDuplicates": false,
	"positionClass": "toast-top-right",
	"showDuration": "5000",
	"hideDuration": "10000",
	"timeOut": "7000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut"
};

function toastHelper(...args) {
	if (arguments.length === 1 && typeof args[0] === 'object') {
		toast(args[0].type, args[0].message, args[0].title, args[0].options);
		return;
	}

	let type = args[0] || "info"
	let message = args[1] || null
	let title = args[2] || null
	let options = args[3] || {}

	toastr[type](message, title, options);
}

export default {
	info(message, title, options) {
		toastr.info(message, title, options)
	},
	error(message, title, options) {
		toastr.error(message, title, options)
	},
	warning(message, title, options) {
		toastr.warning(message, title, options)
	},
	success(message, title, options) {
		toastr.success(message, title, options)
	},
	toast(...args) {
		toastHelper(...args)
	}
}