import toast from "../../common/toast";

let botStatusTimeout;

function runCommand(command) {
	const $botid = $('[data-botid]').data('botid');

	return new Promise((resolve) => {
		axios.post(`bots/command`, {'bot_id': $botid, 'command': command}).then((response) => {
			toast[response.data.type](response.data.message)

			resolve(response.data)
		})
	})
}

function botStatus(loop = true) {
	const $botid = $('[data-botid]').data('botid');

	axios.get(`bots/single/${$botid}`).then((response) => {
		if(response.data.data.status == 0) {
			$('[data-ref="status"]').removeClass('text-danger text-success text-dark').addClass('text-danger');
			$(`[data-ref="status"]`).find('span').html(response.data.lang['offline'])
			$(`[data-action="bot_restart"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_stop"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_reload"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_start"]`).removeClass('visually-hidden').removeAttr('disabled')
		} else if(response.data.data.status == 1) {
			$('[data-ref="status"]').removeClass('text-danger text-success text-dark').addClass('text-success');
			$(`[data-ref="status"]`).find('span').html(response.data.lang['online'])
			$(`[data-action="bot_restart"]`).removeClass('visually-hidden').removeAttr('disabled')
			$(`[data-action="bot_stop"]`).removeClass('visually-hidden').removeAttr('disabled')
			$(`[data-action="bot_reload"]`).removeClass('visually-hidden').removeAttr('disabled')
			$(`[data-action="bot_start"]`).addClass('visually-hidden').attr('disabled')
		} else {
			$('[data-ref="status"]').removeClass('text-danger text-success text-dark').addClass('text-dark');
			$(`[data-ref="status"]`).find('span').html(response.data.lang['unknown'])
			$(`[data-action="bot_restart"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_stop"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_reload"]`).addClass('visually-hidden').attr('disabled')
			$(`[data-action="bot_start"]`).removeClass('visually-hidden').attr('disabled')
		}

		if(!loop) {
			clearTimeout(botStatusTimeout)
		}

		botStatusTimeout = setTimeout(botStatus, 10000)
	});
}

if($('[data-page="botmanager"]').length) {
	botStatus()
}

$('[data-action="bot_start"]').on('click', function() {
	runCommand("start").then((response) => {
		if(response.type === 'success') {
			botStatus(false)
		}
	})
})

$('[data-action="bot_stop"]').on('click', function() {
	runCommand("stop").then((response) => {
		if(response.type === 'success') {
			botStatus(false)
		}
	})
})

$('[data-action="bot_restart"]').on('click', function() {
	runCommand("reload").then((response) => {
		if(response.type === 'success') {
			botStatus(false)
		}
	})
})

$('[data-action="bot_reload"]').on('click', function() {
	runCommand("config").then((response) => {
		if(response.type === 'success') {
			botStatus(false)
		}
	})
})
