import * as bootstrap from 'bootstrap'

(function () {
	'use strict'

	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.querySelectorAll('.needs-validation')

	// Loop over them and prevent submission
	Array.prototype.slice.call(forms)
		.forEach(function (form) {
			form.addEventListener('submit', function (event) {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}

				form.classList.add('was-validated')
			}, false)
		})
})()

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-tooltip]'))
tooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl, {
		placement: tooltipTriggerEl.getAttribute('data-bs-tooltip')
	})
})

var nITooltipTriggerList = [].slice.call(document.querySelectorAll('[data-notimp]'))
nITooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl, {
		placement: tooltipTriggerEl.getAttribute('data-notimp'),
		customClass: 'tooltip-not-implemented',
		title: 'Not Implemented'
	})
})