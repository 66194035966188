$('#register_username').on('focusout', function() {
	if($(this).val() === "" || $(this).val().length < 3) {
		return
	}

	axios.post(`auth/username`, { 'username': $(this).val() })
		.then((response) => {
			if(response.data.exists) {
				$(this).removeClass('is-valid').addClass('is-invalid')
				$('#validation_username').text(response.data.error)
			} else {
				$(this).removeClass('is-invalid').addClass('is-valid')
			}
		})
})

$('#register_email').on('focusout', function() {
	if($(this).val() === "" || $(this).val().length < 3) {
		return
	}

	axios.post(`auth/email`, { 'email': $(this).val() })
		.then((response) => {
			if(response.data.exists) {
				$(this).removeClass('is-valid').addClass('is-invalid')
				$('#validation_email').text(response.data.error)
			} else {
				$(this).removeClass('is-invalid').addClass('is-valid')
			}
		})
})