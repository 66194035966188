import swal from "@/common/swal";

const $botTable = $('#bots').DataTable({
	info: false,
	lengthChange: false,
	pageLength: 20,
	searchDelay: 1500,
	dom: 'lrtp',
	columnDefs: [
		{ targets: [5], orderable: false },
		{ targets: '_all', orderable: true }
	]
})

$('#bot_search').on('keyup keypress', function() {
	$botTable.search($(this).val()).draw()
})

$('[data-action="bot_delete"]').on('click', function () {
	const id = $(this).parents('tr').data('botid');
	const bot = $(this).parents('tr').find("td:eq(1)").text();

	swal.fire({
		title: `Are you sure, that you want delete bot ${bot}?`,
		html: `<span class="text-danger">You won't be able to revert this!</span>`,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes, delete it!',
		allowOutsideClick: false,
	}).then((result) => {
		if (result.isConfirmed) {
			axios.delete(`bot/${id}/delete`).then((result) => {
				if(result.data.status === 'success') {
					swal.fire(
						'Deleted!',
						result.data.message,
						'success'
					)

					$botTable.row($(this).parents('tr')).remove().draw()
				}
			})
		}
	})
})

if(window.location.pathname === '/bots/list') {
	axios.get(`bots/status`).then((response) => {
		for (let key in response.data.data) {
			if (response.data.data[key] == 0) {
				$(`[data-ref="${key}"]`).removeClass('text-danger text-success text-dark').addClass('text-danger')
				$(`[data-ref="${key}"]`).find('span').attr('title', response.data.lang['offline']).attr('data-bs-original-title', response.data.lang['offline'])
			} else if (response.data.data[key] == 1) {
				$(`[data-ref="${key}"]`).removeClass('text-danger text-success text-dark').addClass('text-success')
				$(`[data-ref="${key}"]`).find('span').attr('title', response.data.lang['online']).attr('data-bs-original-title', response.data.lang['online'])
			} else {
				$(`[data-ref="${key}"]`).removeClass('text-danger text-success text-dark').addClass('text-dark')
				$(`[data-ref="${key}"]`).find('span').attr('title', response.data.lang['unknown']).attr('data-bs-original-title', response.data.lang['unknown'])
			}
		}
	})
}