import Swal from "sweetalert2";

export default Swal.mixin({
	buttonsStyling: false,
	customClass: {
		cancelButton: 'btn btn-danger m-1',
		denyButton: 'btn btn-danger m-1',
		confirmButton: 'btn btn-success m-1',
	}
});

