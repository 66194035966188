$.fn.getFormData = function() {
	var data = {};
	var dataArray = $(this).serializeArray();

	for(var i=0; i < dataArray.length; i++) {
		data[dataArray[i].name] = dataArray[i].value;
	}

	return data;
}

$.fn.isValid = function() {
	return this[0].checkValidity()
};

$.fn.reset = function() {
	$(this).trigger('reset')
}