import toast from "../../common/toast";
import swal from "../../common/swal";

// TODO: Move Function Table to ajax request
const $functionTable = $('#functionList').DataTable({
	paging: false,
	info: false,
	searching: false,
	order: [[0, 'desc'], [1, 'asc']],
	columnDefs: [
		{targets: [3], orderable: false},
		{targets: '_all', orderable: true}
	]
});

$('[data-action="toggleFunction"]').on('click', function () {
	let $prefix = $(this).parents('tr').data('functionkey');
	let $id = $(this).parents('tr').data('botid');
	let $state = $(this).data('state')
	let $status = $(this).parents('tr').find('td:eq(0)');

	axios.patch(`bot/${$id}/function/toggle`, {
		'prefix': $prefix,
		'state': $state,
	}).then((response) => {
		toast.success(response.data.status, response.data.message);

		if(response.data.status === 'success') {
			$(this).toggleClass('btn-success btn-danger')
			$(this).data('state', $state === 'activate' ? 'deactivate' : 'activate');
			$(this).find('.fas').toggleClass('fa-play fa-stop')

			$status.attr('data-order', $state === 'activate' ? 1 : 0)
			$status.toggleClass('text-success text-danger').find('.fa-circle').toggleClass('fas far')

			$functionTable.row($(this).parents('tr')).invalidate().draw()
		}
	})
})

$('[data-action="deleteFunction"]').on('click', function () {
	let $prefix = $(this).parents('tr').data('functionkey');
	let $id = $(this).parents('tr').data('botid');

	swal.fire({
		title: `Are you sure, that you want delete function ${$prefix}?`,
		html: `<span class="text-danger">You won't be able to revert this!</span>`,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes, delete it!',
		allowOutsideClick: false,
	}).then((result) => {
		if (result.isConfirmed) {
			axios.delete(`bot/${$id}/function/delete/${$prefix}`)
				.then((response) => {
					toast.success(response.data.status, response.data.message);

					if(response.data.status === 'success') {
						$functionTable.row($(this).parents('tr')).remove().draw()
					}
				})
		}
	})


})

$('[data-action="renameFunction"]').on('click', function() {
	let $curPrefix = $(this).parents('tr').find('td:eq(1)').text()
	let $class = $(this).parents('tr').find('td:eq(2)').text()
	let $id = $(this).parents('tr').data('botid');
	let $newPrefix

	swal.fire({
		title: 'Rename Function',
		icon: 'question',
		input: 'text',
		inputValue: $curPrefix,
		inputPlaceholder: 'New Function Name',
		inputLabel: 'New Function Name',
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: (newPrefix) => {
			$newPrefix = newPrefix

			return axios.post(`bot/${$id}/function/rename`, {
				'class': $class,
				'prefix': $curPrefix,
				'new': newPrefix,
			}).then((response) => {
				if(response.data.status === 'success') {
					return response.data
				}

				toast[response.data.status](response.data.message)
				throw new Error(response.data.message)
			}).catch(error => {
				swal.showValidationMessage(
					`${error}`
				)
			})
		},
		allowOutsideClick: () => !swal.isLoading(),
	}).then((result) => {
		if(result.isConfirmed) {
			toast[result.value.status](result.value.message)

			if(result.value.status === 'success') {
				$(this).parents('tr').data('functionkey', $newPrefix)
				$(this).parents('tr').find('td:eq(1)').text($newPrefix)

				$functionTable.row($(this).parents('tr')).invalidate().draw()
			}
		}
	})
})

$('#addFunction').on('click', function() {
	let $botID = $('#createFunction').data('botid');
	let $function = $('#function').val();
	let $prefix = $('#prefix').val();

	axios.post(`bot/${$botID}/function/add`, { 'class': $function, 'prefix': $prefix})
		.then((response) => {
			toast[response.data.status](response.data.message)

			if (response.data.status === 'success') {
				window.location.reload()
			}
		})
})