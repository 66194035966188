import toast from "@/common/toast";

$('#password-form').submit(function (e) {
	e.preventDefault()

	if (!$('#password-form').isValid()) {
		e.stopPropagation()
		return
	}

	$('#password-change').attr('disabled', true)

	axios.patch('user/security', $(this).getFormData()).then(response => {
		const data = response.data;

		if (data.status === 'success') {
			toast.success(data.message)

			$(this).reset()
			$(':focus').blur()
		} else {
			for(const [index, error] of Object.entries(data.errors)) {
				var field = $(`#${index}`)
				field.addClass(`is-invalid`)
				field.parent().find('.invalid-feedback').text(error)
			}
		}

		$('#password-change').attr('disabled', false)
	})
})